import {ICart} from '@wix/wixstores-graphql-schema';

export class CartIconModel {
  public id?: string;
  public itemsCount?: number;

  constructor(params: CartIconModel) {
    this.id = params.id;
    this.itemsCount = params.itemsCount;
  }

  public static fromGQL(cart: ICart): CartIconModel {
    return new CartIconModel({
      id: cart?.cartId ?? undefined,
      itemsCount: cart?.items ? calculateItemsCount(cart?.items) : undefined,
    });
  }
}

function calculateItemsCount(items: {quantity?: number}[]): number | undefined {
  return items.reduce((prevVal, currVal) => prevVal + (currVal.quantity || 0), 0);
}
